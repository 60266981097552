import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import '../../assets/scss/sub-1.css'
import bannerImg from '../../assets/img/Greetings/Banner.png'
import img01 from '../../assets/img/Work05/work05_01.png'
import img02 from '../../assets/img/Work05/work05_02.jpg'
import img03 from '../../assets/img/Work05/work05_03.png'
import square from '../../assets/img/Work01/square.png'
import btn from '../../assets/img/Work01/work01btn.png'

function Work01contents() {
  const [isActive, setActive] = useState(false)

  const style = {
    logoImg: {
      backgroundImage: `url(${bannerImg})`,
    },

    fontsize: {
      marginTop: '-3%',
      paddingLeft: '4.5%',
      color: '#b3b3b3',
    },
    tableSize: {
      align: 'center',
      borderCollapse: 'collapse',
      marginTop: '6%',
      width: '100%',
      height: '100%',
    },
    spanStyle:{
      width: '2%',
      height: '50%',
      paddingBottom: '1%'
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 50)
  }, [])

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="/jquery-3.3.1.js"></script>
        <script
          type="text/javascript"
          src="/jquery.mCustomScrollbar.css"
        ></script>
        <script type="text/javascript" src="/jquery-migrate-1.4.1.js"></script>
        <script
          type="text/javascript"
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js"
        ></script>
        <script
          type="text/javascript"
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery-cookie/1.4.1/jquery.cookie.js"
        ></script>
        <script type="text/javascript" src="/_sub-side-menu.js"></script>
      </Helmet>
      {/* <section className="subvisu-sec visu-top-gap "> */}
      <section
        className={`subvisu-sec visu-top-gap ${isActive ? 'start' : ''}`}
      >
        <div className="visu-bg" style={style.logoImg}></div>
        <div className="left-bg"></div>
        <div className="right-bg"></div>
        <p>SI Professional Partner</p>
        <h4 style={{  color: 'crimson'}}>-</h4>
        <h3 style={style.margin}>
          {' '}
          自信と誇りを持って、価値創出を追求するSI企業
        </h3>
      </section>
      <section className="cont-sec">
        <div className="con-cont">
          <div className="content-box">
            <div className="left-menu-box">
              <dl id="stick" className="left-menu-dl natural">
                <dt>　SI 事業</dt>
                <dd>
                  <a href="/work01">開発</a>
                </dd>
                <dd>
                  <a href="/work02"> ネットワーク<br></br>セキュリティ</a>
                </dd>
                <dd>
                  <a href="/work03">モバイル開発</a>
                </dd>
                <dd>
                  <a href="/work04">システム</a>
                </dd>
                <dd>
                  <a href="/work05">データベース</a>
                </dd>
              </dl>{' '}
            </div>
            <div id="contbox" className="right-cont-box">
              <div className="contex-box">
                <h2>
                <div className="workSquarediv"><img src={square} className="Square"></img></div>
                  <div className="workTitlediv"> データベース(Oracle・MySQL・SQL Server）の分析<br></br>チューニング・設計・構築・検証・運用～</div>
                 
                </h2>

                <div >
                  <h3 style={style.fontsize}>
                    {' '}
                    データベースの分析・チューニング・設計・構築・検証・運用まで一貫して構築サービス提供します。

                  </h3>
                </div>
                <div className="work04Img01div">
                  <img src={img01} className="work04Img01" />
                </div>
                <div className="work04Img02div">
                  <img src={img02} className="work04Img02" />
                </div>
                <div className="work04Tablediv">
                  <table style={style.tableSize}>
                    <tr className="workTableCss">
                      <td className="workTablefirsttd" align="center">
                        Oracle
                      </td>
                      <td className="workTableCss" align="center">
                        SQLServer
                      </td>
                      <td className="workTableCss" align="center">
                        Sybase
                      </td>
                      <td className="workTableCss" align="center">
                        PostgrSQL
                      </td>
                      <td className="workTablefirsttd" align="center">
                        MySQL
                      </td>
                    </tr>
                  </table>
                </div>
                <div style={{ paddingTop: '10%' }}>
                  <div className="work04msg">
                    基地局パラメータの仕様検討及びパッケージ検証・統計データ分析、
                    基地局容量計算、パラメータ最適化検討・変更を実施電波測定・解析
                    ・調査エリア設計/オプティマイゼーション基地局の建設工事における施工管理業務
                  </div>
                </div>

                <div className="work04Img03div" style={{marginBottom:'-1%'}} >
                    <img src={img03} className="work04Img03" />  
                </div>
                <a href="/contact" >
                <img src={btn} className="workBtn"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="top"></div>
    </div>
  )
}

export default Work01contents
